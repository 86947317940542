<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vg_mb_8">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add" icon="el-icon-plus">新增</el-button>
        <el-button type="danger" size="small" @click="delRow()" :disabled="!btn.delete" icon="el-icon-delete">删除</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="refresh()"> 刷新</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'inquiryId'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getTableData"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/DevelopManagement/RqutManage/rqut';
import { rqutAPI } from '@api/modules/rqut';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'RqutNewList',
  components: { SearchTable },
  data() {
    return {
      tableData: [],
      tableProperties: tableProperties,
      loadFlag: true,
      totalPage: 0,
      multiSelection: [],
      btn: {}
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loadFlag = true;
      rqutAPI.getInquirySheetList(this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.inquirySheeList;
        this.totalPage = data.total;
        this.btn = data.btn;
      });
      this.loadFlag = false;
    },
    dbClickJp(row) {
      this.jump('/rqut_edit', {
        key: UrlEncode.encode(JSON.stringify({ perm_id: this.$route.query.perm_id, form_id: row.rqut_id }))
      });
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    addRow() {
      this.jump(`/rqut_add?perm_id=${this.$route.query.perm_id}`);
    },
    delRow() {
      if (this.multiSelection.length === 0) return this.$message.warning('请至少选择一条数据!');
      let ids = Array.from(this.multiSelection, ({ rqut_id }) => rqut_id);
      rqutAPI.deleteInquirySheeInquiryId({ inquiryIds: ids }).then(({ data }) => {
        this.$message.success('删除成功!');
        this.getTableData();
      });
    },
    refresh() {
      this.$refs.multiTable.resetFields();
      this.getTableData();
    }
  }
};
</script>

<style scoped></style>
