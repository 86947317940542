import { getDateNoTime } from '@assets/js/dateUtils';
import store from '@/store';
import helper from '@assets/js/helper';
import { keepNumber } from '@assets/js/regExUtil';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '系列名称', prop: 'seriesName', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'customerAbbreviation', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '询价单编号', prop: 'inquiryNumber', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '录入人', prop: 'stffName', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '录入时间',
    prop: 'createTime',
    itemType: 'date',
    input: false,
    sortable: true,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    fixed: 'right',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];
export let rqutProperties = [
  {
    label: '系列名称',
    prop: 'seriesName',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '客户简称',
    prop: 'customerAbbreviation',
    itemType: 'select',
    size: 'small',
    model: '',
    options: [],
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '备注',
    prop: 'remark',
    itemType: 'input',
    type: 'textarea',
    rows: 5,
    size: 'small',
    model: '',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    split: 16,
    show: true
  }
];
export const subTableProperties = [
  { prop: 'selection', type: 'selection', labelWidth: '48px', subItem: { disabled: false } },
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号', subItem: { disabled: false } },
  {
    label: '产品名称',
    prop: 'productName',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '我司货号',
    prop: 'productNumber',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '客户货号',
    prop: 'articleNumber',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '工艺图片',
    prop: 'craftPicturesPath',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: {
      type: 'image',
      disabled: false,
      image: val => val.craftPicturesPath + '_m.jpg',
      thumbnail: val => val.craftPicturesPath + '_m.jpg'
    }
  },
  {
    label: '委托打样单号',
    prop: 'entrustedNumber',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '采购部核价',
    prop: 'priceReview',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: false }
  },
  /*  {
    label: '采购部核价日期',
    prop: 'priceReviewTime',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'date', disabled: false }
  },*/
  {
    label: '预计订单数量',
    prop: 'orderQuantity',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: false, input: (val, row) => (row.orderQuantity = keepNumber(val)) }
  },
  {
    label: '单位',
    prop: 'inquiryUnit',
    itemType: 'input',
    input: false,
    labelWidth: '120px',
    sortable: false,
    widthAuto: false,
    subItem: { type: 'select', options: [], disabled: false }
  },
  {
    label: '工厂交期',
    prop: 'deliveryDate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'date', disabled: false }
  },
  {
    label: '包装',
    prop: 'inquiryPackage',
    labelWidth: '150px',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '备注',
    prop: 'inquiryRemark',
    itemType: 'input',
    labelWidth: '150px',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '询价工厂 / 价格',
    prop: 'inquiryFactory',
    itemType: 'input',
    labelWidth: '300px',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '采购部确认工厂',
    prop: 'confirmFactory',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '采购部确认价',
    prop: 'confirmPrice',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: true, placeholder: '选择工厂带入' }
  },
  {
    label: '备注',
    prop: 'confirmRemark',
    itemType: 'input',
    labelWidth: '150px',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: false }
  }
];
export const dialog1Properties = [
  store.state.selection,
  store.state.index,
  { label: '产品名称', prop: 'smpl_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '我司货号', prop: 'smpl_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '工艺图片',
    prop: 'imge_url',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'image',
      image: row => row.imge_url + '_l.jpg',
      thumbnail: row => row.imge_url + '_m.jpg'
    }
  },
  { label: '委托打样单号', prop: 'requ_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '备注',
    prop: 'quta_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true
  }
];
export const dialog2Properties = [
  store.state.selection,
  store.state.index,
  { label: '二部报价单号', prop: 'qutb_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '工厂简称', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: true, sortable: false, widthAuto: true }
];
export const dialog3Properties = [
  store.state.selection,
  store.state.index,
  { label: '委托打样单号', prop: 'requ_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '工厂简称', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true }
];
